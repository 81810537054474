"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVal = void 0;
var getVal = function (obj) {
    var tmp = [];
    Object.keys(obj).forEach(function (key) {
        var lang = key, name = obj[key] === '' ? null : obj[key];
        tmp.push({
            lang: lang,
            name: name,
        });
    });
    return tmp;
};
exports.getVal = getVal;
